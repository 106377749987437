.expandable-container {
  display: grid;
  overflow: hidden;
  grid-template-rows: 0fr;
  transition: grid-template-rows 200ms ease-out;

  &.open {
    grid-template-rows: 1fr;
  }

  .__content {
    min-height: 0;
  }
}
