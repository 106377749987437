.scroll-controller-wrapper {
  .scroll-controller-progress {
    &::-webkit-progress-bar {
      @apply bg-gray-200;
    }
    &::-webkit-progress-value {
      @apply bg-wb-red;
    }
  }

  .scroll-controller-handle {
    transition: left 150ms ease;
  }
}
.scroll-slider {
  -webkit-appearance: none;
  appearance: none;
  background: #eaeaea;
  accent-color: blue;
  border-radius: 20px;
  cursor: pointer;
  width: 100%;
  padding: 0;
  height: 8px;
  //   &::-webkit-slider-runnable-track {
  //     background: #b3070f;
  //     height: 4px;
  //   }
  &::-webkit-slider-thumb {
    &:active {
      cursor: grab;
    }
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 24px; /* Set a specific slider handle width */
    height: 24px; /* Slider handle height */
    border-radius: 100%;
    background: #b3070f;
    cursor: pointer; /* Cursor on hover */
  }
}
