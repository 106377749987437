.HouseModel {
  perspective: 1000px;
}

.house-roof {
  transform: rotateX(20deg);
  background-color: #6c6d73;
  background-image: url('../../../assets/images/roof.jpg');
  background-size: contain;
  box-shadow: 0px 7px 5px 0px rgb(44 44 44 / 75%);
}

.grid-wrapper {
  transform: rotateX(10deg);
}
