input.custom-checkbox {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15rem;
  aspect-ratio: 1/1;
  border: 2px solid currentColor;
  border-radius: 4px;
  position: relative;
  min-width: 18px;
  @apply text-border-gray;

  &::before {
    content: url('data:image/svg+xml,<svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 7L7 12L17 2" stroke="%23B3070F" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%) scale(0.75);
    transition: 100ms all ease-in-out;
    opacity: 0;
  }

  &:checked::before {
    opacity: 1;
  }
}
