/* width */
*::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}

/* Track */
*::-webkit-scrollbar-track {
  @apply bg-gray-400;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  @apply bg-wb-red;
  border-radius: 20px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
