.grid-warning-info {
  &.zInd {
    z-index: 100000;
  }
  .warning-icon {
    transition: scale 200ms ease;
  }
  &:hover {
    .warning-icon {
      scale: 0.85;
    }
  }
}
