.configurator-wrapper {
  perspective: 1000px;

  .configurator-surface-wrapper {
    transform: rotateX(10deg) scale(0.9);
    max-width: 100vw;
    background-color: #6c6d73;
    background-image: url('../../../assets/images/roof.jpg');
    background-size: contain;
    box-shadow: 3px 8px 30px 2px rgba(102, 102, 102, 0.32);
  }
  .configration-right-point {
    right: auto;
    left: calc(100% + 23px);
  }
}
