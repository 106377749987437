.Select {
  min-width: 160px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml,<svg id="Group_1558" data-name="Group 1558" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 30 30"><circle id="Ellipse_2" data-name="Ellipse 2" cx="15" cy="15" r="15" transform="translate(0 0)" fill="%23a5a6a6"/><path id="Path_4" data-name="Path 4" d="M5.288,6.3.21,1.225A.717.717,0,1,1,1.225.21L5.288,4.273,9.35.21a.717.717,0,0,1,1.015,1.015Z" transform="translate(9.711 12.438)" fill="%23fff"/></svg>');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 0.5rem);
  background-position-y: 50%;
}

.CompanyDetails  select
{
  width: 100%;
  border: 1px solid #D0D0D0;
}