@tailwind base;
@tailwind components;
@tailwind utilities;

@import './sass/scroll';

html {
  @apply font-noto-sans scroll-smooth;
}

.light-border-bottom {
  @apply border-b-[1px] border-medium-grey
}

.resource-item-padding-bottom {
  @apply pb-10 md:pb-20
}

.input-label {
  @apply text-medium-grey flex-1 text-sm md:text-base whitespace-nowrap
}

.elevation-header {
  @apply font-bold text-lg
}