.Results {
}

.RightContainer {
  height: 46vh;

  @media screen and (min-width: 450px) {
    height: 50vh;
  }

  @media screen and (min-width: 550px) {
    height: 55vh;
  }
  @media screen and (min-width: 650px) {
    height: 60vh;
  }

  @media screen and (min-width: 768px) {
    height: 380px;
  }
  @media screen and (min-width: 970px) {
    height: 480px;
  }
}
